import React from "react";

function PokeHeader() {
    return (
        <div>
            <h1></h1>
        </div >
    )
};

export default PokeHeader;